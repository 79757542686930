@import "themes";

@import "~@nebular/theme/styles/globals";

@include nb-install() {
  @include nb-theme-global();
}
/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "assets/scss/settings.scss";
@import "assets/scss/mixins";

/* Fonts */
@import "assets/scss/fonts";

@import "assets/scss/global.scss";

@import "assets/scss/components/form";
@import "assets/scss/components/nebular-overrides";
@import "assets/scss/components/icons";
@import "assets/scss/components/modal";

@import "app/app.component.scss";
