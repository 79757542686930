input[type="text"] {
  ~ input[type="text"] {
    margin-top: 20px;
  }
}

nb-radio {
  &:hover {
    .outer-circle {
      background-color: $dark-gray !important;
      border-color: $dark-gray !important;
    }
  }
}

nb-checkbox {
  &:hover {
    .custom-checkbox {
      background-color: $dark-gray !important;
      border-color: $dark-gray !important;
    }
  }
}

.dropdown-item {
  cursor: pointer;
  padding: 18px 22px 16px;
}

.ng-select {
  .ng-select-container {
    border-color: $light-gray !important;
    background-color: $light-gray;
    border-radius: $border-radius;
    min-height: 40px;

    .ng-value-container {
      font-family: $font-red-hat;
      font-size: 11px;
      font-weight: 900;
      line-height: 13.2px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding-top: 3px;

      .ng-placeholder {
        color: $black;
      }
    }

    .ng-arrow-wrapper {
      padding-right: 15px;
      width: 31px;
      .ng-arrow {
        border: none;
        display: block;
        margin-right: 0;
        @include color-svg("icn-arrow", $black, 16px, 9px);
        transition: transform 0.2s;
      }
    }
  }

  .ng-dropdown-panel {
    border-color: $light-gray;
    .ng-dropdown-panel-items {
      .ng-option {
        font-family: $font-red-hat;
        font-size: 11px;
        font-weight: 600;
        line-height: 13.2px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $black;

        &.ng-option-marked {
          background-color: $light-gray;
        }

        &.ng-option-selected {
          background-color: $light-gray;
          color: $black;
          font-weight: 900;
        }
      }
    }
  }

  &.ng-select-opened {
    > .ng-select-container {
      .ng-arrow {
        top: 0;
        transform: rotate(180deg);
      }
    }
  }

  &.ng-select-focused:not(.ng-select-opened) {
    > .ng-select-container {
      box-shadow: none;
    }
  }
}

label {
  font-family: $font-red-hat;
  font-size: 13px;
  font-weight: 300;
  line-height: 15.63px;
}

ng-select {
  &.disabled {
    .ng-select-container {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

form {
  .label-error {
    color: $red !important;
  }
  .error-container {
    background-color: $red;
    border-radius: $border-radius;
    padding: 12px 15px 12px !important;
    font-family: $font-red-hat;
    font-size: 13px;
    font-weight: 300;
    line-height: 15.63px;
    color: $white;
    position: relative;
    margin-top: 9px;

    .icon {
      position: absolute;
      top: calc(50% - 8px);
      right: 15px;
    }
  }

  nb-radio-group {
    + .error-container {
      margin-top: 4px;
    }
  }

  [formgroupname="fileFormats"] {
    + .error-container {
      margin-top: 3px;
    }
  }

  &.ng-submitted {
    .ng-invalid {
      border-color: $red !important;

      .custom-checkbox,
      .outer-circle {
        background-color: $red !important;
        border-color: $red !important;
      }

      .ng-select-container {
        border-color: $red !important;
      }
    }
  }
}
