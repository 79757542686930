.icon {
  &.icon-error {
    @include color-svg("icn-error", $white, 16px, 16px);
  }
  &.icon-random {
    @include color-svg("icn-random", $white, 17.5px, 15px);
  }
  &.icon-plus {
    @include color-svg("icn-plus", $white, 15px, 15px);
  }
  &.icon-information {
    @include color-svg("icn-info", $black, 16px, 16px);
  }
  &.icon-close {
    @include color-svg("icn-close", $black, 24px, 24px);
  }
  &.icon-play {
    @include color-svg("icn-play", $black, 111px, 111px);

    &.black {
      background-color: $black;
    }
  }
}
