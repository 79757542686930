// Typography
// -------
h1 {
  font-family: $font-red-hat;
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
  margin-bottom: 45px;
  color: $black;

  a {
    color: $black;
    &:hover {
      color: $black;
      text-decoration: none;
    }
  }
}
h2 {
  font-family: $font-red-hat;
  font-weight: 900;
  font-size: 18px;
  line-height: 21.6px;
  letter-spacing: 0.1em;
  margin-bottom: 35px;
  text-transform: uppercase;
  color: $black;
}
h3 {
  font-family: $font-red-hat;
  font-weight: 900;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: $black;
}
h4 {
  font-family: $font-bitter;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  color: $black;
}
h5 {
  font-family: $font-red-hat;
  font-size: 13px;
  line-height: 15.63px;
  margin-bottom: 20px;
  color: $black;
}
h6 {
  font-family: $font-red-hat;
  font-weight: 900;
  font-size: 10px;
  line-height: 13.2px;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  margin-top: 50px;
  color: $green;
}

p {
  font-family: $font-bitter;
  font-size: 13px;
  line-height: 21px;
  color: rgba($black, 0.8);

  &.small {
    font-size: 11px;
    line-height: 13.2px;
  }
}
hr {
  margin-top: 35px;
  margin-bottom: 35px;
  border-color: $dark-gray;
  border-width: 2px;
}

*:focus {
  outline-style: none !important;
  box-shadow: none !important;
}

.spacer-40 {
  margin-bottom: 40px;
}

// Links
// -------
a {
  &.textlink {
    font-family: $font-red-hat;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    margin-bottom: 45px;
  }
}

button,
.button {
  display: inline-block;
  font-family: $font-red-hat;
  font-weight: 700 !important;
  font-size: 11px !important;
  line-height: 13.2px !important;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white !important;
  background-color: $black;
  padding: 11px 15px 10px;
  border: 1px solid $black;
  border-radius: $border-radius;
  margin-bottom: 100px;
  text-decoration: none !important;
  height: 38px;

  &:hover {
    background-color: $darker-gray;
    border-color: $darker-gray;
  }

  &.inverted {
    background-color: $white;
    color: $black !important;
    &:hover {
      color: $darker-gray !important;
    }
  }

  &:disabled {
    border-color: $light-gray;
    background-color: $light-gray;
    color: $gray !important;
  }

  &.green {
    background-color: $green;
    border: none;
    color: $black !important;

    &:hover {
      background-color: $light-green;
      border-color: $light-green;
    }
    &:active {
      background-color: $dark-green;
      border-color: $dark-green;
      color: $black-pressed !important;
      -webkit-box-shadow: inset 0px 2px 0px $darker-green !important;
      -moz-box-shadow: inset 0px 2px 0px $darker-green !important;
      box-shadow: inset 0px 2px 0px $darker-green !important;
    }
  }
}

img {
  width: 100%;
  height: auto;
}

ul {
  padding-left: 16px;
  padding-right: 20px;
  li {
    padding-bottom: 10px;
  }
}
