.modal-content {
  border-radius: $border-radius;

  .modal-header {
    align-items: center;
    .modal-title {
      text-transform: uppercase;
    }
    .btn-close {
      margin-bottom: 0;
      padding: 0;
      border: 0;
      background-color: transparent;
    }
  }

  .modal-body {
    input {
      font-family: $font-bitter;
    }
  }

  .modal-footer {
    .button {
      margin-bottom: 0;
    }
  }
}
