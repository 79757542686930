.nb-theme-default {
  [nbInput].size-medium {
    padding: 11px 14px !important;
  }
  [nbInput].size-medium::placeholder {
    opacity: 1;
  }

  nb-stepper {
    .step {
      margin: 0 !important;
      &:first-child {
        align-items: flex-start !important;
      }
      &:last-child {
        align-items: flex-end !important;
      }

      .label {
        font-family: $font-red-hat;
        font-weight: 900;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: $gray;
        letter-spacing: 0.1em;
      }

      &.selected {
        .label-index {
          background-color: $green;
        }
        .label {
          color: $green;
        }
      }

      &:not(.selected):hover {
        .label {
          color: $green;
        }
        .label-index {
          border-color: $green;
        }
      }

      &.completed {
        .label {
          color: $black;
        }
      }
    }
  }

  .color-picker {
    nb-tabset {
      .tabset {
        padding-left: calc((100% / 12) + 15px);

        .tab {
          overflow: hidden;
          padding-bottom: 0;
          &.active {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          &:hover {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
      }
    }
  }

  nb-checkbox,
  nb-radio {
    display: inline-block;
    label {
      cursor: pointer;
    }
  }

  nb-radio-group {
    display: block;
  }

  nb-checkbox {
    .custom-checkbox-icon {
      @include color-svg("icn-checkmark", $green, 9px, 6px);
      svg {
        display: none;
      }
    }
  }

  nb-tabset .tab-link {
    letter-spacing: 1px;
  }
  .color-picker nb-tabset .tabset .tab.active {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  nb-tabset .tab:not(.active):hover .tab-link {
    color: $green;
    background-color: inherit;
  }
  nb-tabset .tab.active:hover .tab-link {
    color: $green;
  }
}
