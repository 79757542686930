// Mixin für verfärbare SVGs
// Parameter Bildpfad, Farbe, Breite, Höhe
// Beispiel: @include color-svg('logo', red, 140px, 40px);
@mixin color-svg($image, $color, $width, $height) {
  -webkit-mask-image: url("#{$image-path}/#{$image}.svg");
  mask-image: url("#{$image-path}/#{$image}.svg");
  background-color: $color;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  display: inline-block;
}
