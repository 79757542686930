//----------------------
// Red Hat Font Mixin
//----------------------

@mixin printRedHatFont(
  $weightValue: 400,
  $weightName: normal,
  $familyName: "RedHatText",
  $style: "normal",
  $relative: true
) {
  $filePath: "./" + $familyName + "/" + $familyName + "-" + $weightName;

  @if $relative == false {
    $filePath: $fontLocation +
      "/" +
      $familyName +
      "/" +
      $familyName +
      "-" +
      $weightName;
  }

  // Using woff2 gives us the following browser support:
  // Chrome 36+
  // Safari 10+
  // Firefox 39+
  // Opera 23+
  // Edge 14+
  // Android 5+
  // iOS 10+
  @font-face {
    font-family: $familyName;
    src: url("#{$filePath}.woff2") format("woff2");
    font-style: #{$style};
    font-weight: $weightValue;
    // https://calendar.perfplanet.com/2020/a-font-display-setting-for-slow-connections/
    font-display: fallback;
  }
}

@font-face {
  font-family: "RedHatText";
  src: url("../font/RedHatText/RedHatText-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "RedHatText";
  src: url("../font/RedHatText/RedHatText-Medium.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "RedHatText";
  src: url("../font/RedHatText/RedHatText-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "RedHatText";
  src: url("../font/RedHatText/RedHatText-Light.woff2") format("woff2");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Bitter";
  src: url("../font/Bitter/Bitter-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

$fallback-fonts: Arial, Helvetica, sans-serif;
$fallback-fonts-serif: "Times New Roman", Times, serif;
$font-red-hat: "RedHatText", $fallback-fonts;
$font-bitter: "Bitter", $fallback-fonts-serif;
