$white: #ffffff;
$black: #000000;
$black-pressed: #062516;
$light-gray: #f2f2f2;
$alt-gray: #e8e8e8;
$disabled-gray: #eaeaea;
$gray: #b3b3b3;
$dark-gray: #707070;
$darker-gray: #404040;
$green: #26d07c;
$light-green: #5ddc9d;
$dark-green: #21ba6e;
$darker-green: #1a9458;
$light-blue: #6ab2e7;
$tainted-blue: #507289;
$dark-blue: #042c58;
$mint-green: #77b6ba;
$petrol-green: #006b6b;
$purple: #4c3575;
$pink: #d13896;
$red: #e31b4c;
$orange: #ffa252;

$image-path: "../img";

$border-radius: 4px;
